/* src/pages/Welcome/welcome.css */

.welcomeWrapper {
  /*  set background image to take up entire screen */
  min-height: 100vh;
  width: 100vw;

  /* set image url and gradient overlay */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url(https://i.imgur.com/7pFMD4w.jpg) no-repeat center center fixed;

  /* crossbrowser support  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.welcomeWrapper a {
  text-decoration: none;
}

.welcomeBannerText {
  /* font styling on banner */
  font-size: 6rem;
  letter-spacing: 1rem;
  line-height: 8rem;
  font-family: Vintages;

  padding-top: 15vh;
}

@media only screen and (max-width: 600px) {
  .welcomeBannerText {
    font-size: 3rem;
    letter-spacing: 0.25rem;
    line-height: 4rem;
  }
}

/* button animations */

/* CSS Button reference: https://cssbuttons.app/?utm_source=toolkit.addy.codes*/

/* Button inspo  */

.hover {
  display: flex;
  flex-direction: row;
}

.box {
  /* sizing */
  width: 90px;
  height: 65px;
  padding: 25px;

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;

  /* font */
  font-size: 25px;
  font-weight: 700;
  color: #fff;

  /* other */
  transition: all 0.8s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/* both buttons */
.hover:hover .box:before {
  transform: translateY(0);
}

/* border settings */
.box1 {
  border: 2px solid white;
  border-right: none;
}

.box2 {
  border: 2px solid white;
  border-left: none;
}

/* Over 21 button settings */

.box-overage:before {
  content: "Green";
  position: absolute;
  top: 0;
  background: #39ff14;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  transition: transform 0.4s;
}

.box-overage:nth-child(2)::before {
  transform: translateY(-100%);
  content: "Light!";
}

/* Under 21 button settings */

.box-underage:before {
  content: "Red";
  position: absolute;
  top: 0;
  background: red;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
  transition: transform 0.4s;
}

.box-underage:nth-child(2)::before {
  transform: translateY(100%);
  content: "Light!";
}

/* End age gateway settings */

/* socials */

.socials-icon {
  text-decoration: none;
  color: white;
}

.socials-icon:hover {
  color: #39ff14;
}
