/* frontend/src/components/MenuComponents/BeerCard/beercard.css */

.vert-text {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.beer-card {
  background-color: transparent;
  width: 40%;
  border: none;
  padding: 0;
  /* border: 3px solid slategray; */
}

.label-container {
  width: 40%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .beer-card {
    width: 100%;
    padding: 5%;
  }

  .label-container {
    display: none;
  }

  .label {
    display: none;
  }
}
