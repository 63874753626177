/* frontend\src\components\Hero\hero.css */

/* media query at bottom */

.hero {
  /* Sizing */
  max-width: 100vw;
  height: 100vh;

  /* Flexbox stuff */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Text styles */
  text-align: center;
  color: white;
  letter-spacing: 2rem;

  /* Background styles */
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url("https://i.imgur.com/7pFMD4w.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hero h1,
h2 {
  /* Text styles */
  font-size: 6em;
  color: #59ff48;
  font-family: Vintages;
  font-weight: 900;

  /* Margins */
  margin-top: 0;
  margin-bottom: 0.5em;
}

.hero .btn {
  /* Positioning and sizing */
  display: block;
  width: 200px;

  /* Padding and margins */
  padding: 1em;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  /* Text styles */
  color: black;
  text-decoration: none;
  font-size: 1.5em;

  /* Border styles */
  border: 3px solid black;
  border-radius: 20px;
}

.hero-scroll {
  color: #59ff48;
  font-weight: 900;
  animation: bounceAnim 2s linear 1s 1 normal none;
}

.hero-scroll:hover {
  cursor: pointer;
}

@keyframes bounceAnim {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    letter-spacing: normal;
  }

  .hero h1,
  h2 {
    font-size: 4rem;
  }
}
