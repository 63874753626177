/* frontend/src/components/MenuComponents/FoodCard */

.food-item {
  /* border: 5px solid green; */
  width: 40%;
  height: 9rem;
  background-color: transparent;
}

.food-label-container {
  max-width: 10rem;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.food-label {
  max-width: 100%;
  height: 80%;
}

@media only screen and (max-width: 800px) {
  .column-1 {
    display: none;
  }

  .food-item {
    width: 100%;
    margin: 0 auto;
    padding: 5%;
  }

  .food-middle-row {
    flex-direction: column-reverse;
  }
}
