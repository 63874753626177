/* frontend/src/components/TopNav/topnav.css */

.navbar-nav {
  display: flex;
  gap: 1.5rem;
  font-size: 1.25rem;
}

.nav-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 55vw;
}

li a {
  color: lightgrey;
  text-decoration: none;
}

li a:hover {
  color: #59ff48;
}

@media only screen and (max-width: 600px) {
  .nav-group {
    flex-direction: column;
    width: 100%;
  }
}
