/* frontend\src\components\HomeInfo\homeinfo.css */
.homeInfoWrapper {
  /* Sizing */
  max-width: 100vw;
  min-height: 75vh;

  /* Text styles */
  text-align: center;
  color: white;

  /* Background styles */
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url("https://i.imgur.com/7pFMD4w.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.homeInfoWrapper a {
  text-decoration: none;
}

.card-link {
  color: white;
  text-decoration: none;
}

.card-link:hover {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .homeInfoButtonsContainer {
    justify-content: space-evenly;
    align-items: center;

    gap: 2rem;
  }
}
