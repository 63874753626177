/* frontend/src/components/Footer/footer.css */

.footer-wrapper {
  padding: 10px 10px 0px 10px;
  width: 100%;
  /* Height of the footer*/
  min-height: 20vh;

  /* flex properties */
  justify-content: space-evenly;
}

.footer-content-row {
  justify-content: space-evenly;
  align-items: start;
}

.contact-wrapper a {
  text-decoration: none;
  color: white;
}

.contact-wrapper a:hover {
  color: #59ff48;
}

@media only screen and (max-width: 700px) {
  .footer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-content-row {
    justify-content: center;
    align-items: center;
  }
  .contact-wrapper {
    justify-content: center;
    align-items: center;
  }
}
